body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body {
  background-color: #231E78;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
h1 {
  color: #EF913A;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding-right: 25px;
  padding-left: 25px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 5px;
}
.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 0px;
}

#updateTable {
  width: 90%;
  margin: 10px;
}

#updateTable, #updateTable tr {
  border: 1px solid black;
  vertical-align: top;
}

#updateTable td {
  padding-bottom: 20px;
}

.columnDate {
  width: 100px;
}

.columnAuthor {
  width: 175px;
}

.monospacedDate {
  font-family: monospace;
  font-size: 14px;
}

#outage {
  padding: 20px;
  background-color: rgb(192,0,0);
  color: rgb(255,255,255);
}

#outage a {
  color: rgb(224,224,224);
  font-weight: bold;
}