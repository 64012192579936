.scroll {
    overflow: auto;    
  }
  
  .search-sidebar {
    height: 100%;
    overflow: auto; 
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: visible;
  }

.looker_ratings {
  display: inline-block;
  padding: 1px 6px;
  margin: 4px 0;
  background-color: #eee;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 12px;
display: inline-block;
font-size: smaller;
}

.lim_page {
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  display: inline-block;
  font-size: smaller;
}

.course_update_dropdown ul{
  position: static;
}

.choice_correct i{
  margin-right: 15px;
}

.choice_explanation {
  font-style: italic;
}

.course_updates_sectionname {
  font-weight: bold;
}

.search_filter {
  margin-top: 5px;
  margin-bottom: 5px;
}

.coursechange-header{
  cursor: pointer;
  border: solid 1px #f2f2f2;
  border-radius: 5px;
  padding: 5px;
  background-color: #62656b;
  color: #FFF;
  }
  .coursechange-contentold{
  font-size: x-small;
  cursor: pointer;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: verdana;
  }

  .coursechange-content{
    font-size: x-small;
    cursor: pointer;
    padding: 15px;
    font-family: verdana;
    }
  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
  .topics {
    width: 500px;
    height: 200px;
  }
  
  .topicchart {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 10px;
      width: 800px;
      height: 800px;
      background-color: #fff;
    }

  .tag {
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }
  
  .course-relationship-change-item {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    margin: 4px 0;
    background-color: rgb(91, 64, 247);
    border-radius: 2px;
    margin-right: 5px;
  }

  .generic-section-change-item {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    margin: 4px 0;
    background-color: rgb(47, 120, 255);
    border-radius: 2px;
    margin-right: 5px;
  }

  .results-filter{
    font-size: smaller;
    padding: 0px;
    overflow-y: scroll;
  }

  .ZenDeskDetails {
    padding:3px; 
}

.zendesk_hyperlink {
  font-size: smaller;
}

.zd_feedback_wrapper {
margin: 5px;
background-color: #faf9f9;
}


.zendeskcontent {
  font-size: smaller;
    height:700px;
    overflow:auto;
}

.zd_ticket_description {
  font-size: smaller;
  font-style: italic
}

.AsanaTaskWidget {
    height: 60%;
    padding:3px; 
}

.asanataskcontent {
  font-size: smaller;
    height:250px;
    overflow:auto;
}

.asanataskcontent p{
  margin-bottom: 2px;;
}
  div:asanaTaskWidget p{
    margin-bottom: 0px;
  }

  .asanataskcompletedtrue {
    font-size: smaller;
    color:rgb(0, 102, 34);
    margin-bottom: 0px;
  }
  .asanataskcompletedtrue:before {
    color:rgb(0, 102, 34);
    font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\2713";
  }
  .asanataskcompletedfalse {
    font-size: smaller;
    margin-bottom: 0px;
    color:rgb(47, 120, 255)
  }


  .zendesk_ticket_solved  {
    color:rgb(0, 102, 34);
  }

 .zendesk_ticket_closed {
    color:rgb(0, 102, 34);
  }

  .zendesk_ticket_solved:before {
    color:rgb(0, 102, 34);
    font-size: smaller;
    font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\2713";
  }
  .zendesk_ticket_closed:before {
    color:rgb(0, 102, 34);
    font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\2713";
  }


  .zendesk_content_type_video {
    font-size: smaller;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: rgb(218, 240, 193);
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .zendesk_content_type_quiz {
    font-size: smaller;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: rgb(232, 193, 240);
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .zendesk_content_type_hands-on-lab {
    font-size: smaller;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: rgb(193, 209, 253);
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .zendesk_content_type_course {
    font-size: smaller;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .lesson-count-change-item {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    margin: 4px 0;
    background-color: rgb(202, 81, 0);
    border-radius: 2px;
    margin-right: 5px;
  }

  .fewer-lessons-change-item {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    margin: 4px 0;
    background-color: rgb(0, 102, 34);
    border-radius: 2px;
    margin-right: 5px;
  }
  
  .more-lessons-change-item {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    margin: 4px 0;
    background-color: rgb(185, 0, 0);
    border-radius: 2px;
    margin-right: 5px;
  }

  .course_tag {
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .change_detailed_json {
    visibility:collapse;
    display: inline-block;
    color: grey;
    font-style: italic;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .AsanaTasks {
    display: inline-block;
    color: grey;
    font-style: italic;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 8px;
  }

 /* o .linkedassessment:before {
    content: "Found in: ";
  }*/

  .linkedassessment {
    color: grey;
    font-weight: bold;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color:  #3c8d47;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .linkedassessment a {
    color: white;
  }

  .nolinkedassessment {
    color: white;
    font-weight: bold;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color:  #ff6f7b;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .lab_description {
    text-decoration: none;
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #ecebff;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }

  .assessment {
    display: inline-block;
    padding: 1px 6px;
    margin: 4px 0;
    background-color: #ecebff;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 12px;
  }
  div#signoutbutton
{
   width:150px; /* or whatever width you want. */
   max-width:250px; /* or whatever width you want. */
   display: inline-block;
}



  .btn {
    flex: -1;
    border-radius: 5px;
    border: 1px solid skyblue;
  }
  
  .btn > a {
    text-decoration: none;
  }
  
  .fa-star {
    color: rgb(255, 169, 27) !important;
  }
  
  .box {
    margin-top: 10px !important;
    flex-direction: column;  

  }
  
  .data-search-width {
    width: 10%;
  }
  
  .links {
    margin-left: 10px;
    margin-top: 5px;
  }
  
  .link {
    margin-left: 5px;
  }
  
  .marginBottom {
    margin-bottom: 50px;
  }
  
  .dataSearch {
    margin-top: 10px;
  }
  